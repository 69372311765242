import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {graphql} from 'gatsby'
import SetupImg from '../../assets/images/easy-to-configure/setup-icon.svg'
import SmartAndEasyImg from '../../assets/images/easy-to-configure/smart-and-easy.webp'
import IntuitiveAndFriendlyImg from '../../assets/images/easy-to-configure/intuitive-and-friendly.webp'
import UsersImg from '../../assets/images/easy-to-configure/users-icon.svg'
import HostPortSettingsImg from '../../assets/images/easy-to-configure/host-port-settings.webp'

const img = '/img/features/BlueMail_Features_Easy_to_Configure_v2.png'





const EasyToConfigure = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='Easy to Configure | BlueMail App' 
                postDescription='Setup is an effortless breeze and by simply signing into your email accounts.'
                postImage={img}
                postURL='features-functions/easy-to-configure'
                postSEO
            />
            <Navbar />
            <div className='features-functions easy-to-configure'>
              <div className='container pt-120'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                    <img src={SetupImg} alt='setup icon' />
                    <h2>The <span>Smart</span> & <span>Easy</span> Way to Configure Email Accounts</h2>
                    <p>BlueMail simplifies the process of setting up email accounts. The software automatically configures accounts using various protocols, including IMAP, SMTP, Exchange, ActiveSync, EWS, and POP3. This saves users time and effort, as they no longer need to manually enter complex settings. With BlueMail, users can start using their inbox right away, hassle-free. Automatically Configures Multiple Protocols.</p>
                  </div>                      
                  <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                    <img className='mt-mob' src={SmartAndEasyImg} alt='Smart And Easy' />
                  </div>
                </div>
              </div>   
              <div className='container pt-120'>
                <div className='row rev'>
                  <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                    <img src={IntuitiveAndFriendlyImg} alt='Intuitive And Friendly' />
                  </div>
                  <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                    <img src={UsersImg} alt='setup icon' />
                    <h2><span>Intuitive</span> & <span>User-Friendly</span> Configuration Process</h2>
                    <p>BlueMail streamlines the email setup process, making it more intuitive and user-friendly. With its automatic configuration, users don't need any technical knowledge to set up their accounts. BlueMail detects the type of account users have and automatically configures it using optimal settings. This feature simplifies the process, enabling users to get started with their inbox in no time.</p>
                  </div>    
                </div>
              </div>   
              <div className='container pt-120 pb-100 m-top'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                    <h2>Automatic Detection of <span>Host and Port Settings</span></h2>
                    <p>BlueMail makes email setup effortless by automatically detecting the appropriate host and port settings for users' email accounts. This feature eliminates the need for users to manually enter complex settings, such as server names, ports, and encryption types. With BlueMail, users can enjoy a hassle-free email experience, knowing that their accounts are configured with the optimal settings.</p>
                  </div>                      
                  <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                    <img className='mt-mob' src={HostPortSettingsImg} alt='Host and Port Settings' />
                  </div>
                </div>
              </div>           
            </div>
            <Footer />
        </Layout>
    )
}

export default EasyToConfigure

export const query = graphql`
query EasyToConfigurePageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`